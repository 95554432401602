import React from 'react';

import Layout from '../../components/Layout';
import Product from '../../components/Product';
import Collaborations from '../../components/Collaborations';
import Description from '../../components/Description';
import Flexible from '../../components/Flexible';
import LegalSpotlight from '../../components/LegalSpotlight';

const CorporateLegalLawFirms = ({ path }) => (
  <Layout
    pageName="why"
    path={path}
    title="Why Fibonacci - Fibonacci"
    image="/metaimages/why-fib.jpg"
    description="Gain a 360° view on where work stands without bloated spreadsheets and endless emails."
  >
    <div className="banner -why">
      <div className="chip">CORPORATE LEGAL & LAW FIRMS</div>
      <h1>Work beautifully</h1>
      <p>
        Gain a 360&deg;  view on where work stands without bloated
        <br />
        spreadsheets and endless emails.
      </p>
    </div>
    <Product />
    <Collaborations />
    <Flexible />
    <Description />
    <LegalSpotlight />
  </Layout>
);

export default CorporateLegalLawFirms;
